import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, theme } from 'antd';
// import('antd/dist/antd.min.css');

// if (window.localStorage.theme === 'dark') {
//   import('antd/dist/antd.dark.min.css');
// } else if (window.localStorage.theme === 'compact') {
//   import('antd/dist/antd.compact.min.css');
// } else {
//   import('antd/dist/antd.min.css');
// }

const token = {
  colorPrimary: "#0c75de",
  borderRadius: '0px'
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={
        window.localStorage.theme === 'dark' ? { algorithm: theme.darkAlgorithm } : window.localStorage.theme === "compact" ? { algorithm: theme.compactAlgorithm } : { token }
      }
    >
      <App />
    </ConfigProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
