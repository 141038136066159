const config = {

    apiUrl: 'https://api.cakingom.com/admin/',
    // apiUrl: 'http://localhost:5505/admin/'

    // socketUrl: 'https://api.cakingom.com'
    
}

export default config
